import React, { Component } from 'react';

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import axios from 'axios';

import isEmpty from 'lodash/isempty';
import find from 'lodash/find';
import filter from 'lodash/filter';

import SurveyQuestion from './SurveyQuestion';
import Form from './Form';

import sendEventToCounters from '../countersEvents';

let _DATA = {};

export default class Survey extends React.Component {
    targetElement = null;

    constructor(props) {
      super(props);

      this.domContainer = React.createRef();

      this.state = {
        isOpen: false,
        isSurveyEnded: false,
        currentQuestion: 0,
        lastQuestion: false,
        data: {},
        selectedAnswers: [],
      };

      this.updateCurrentQuestion = this.updateCurrentQuestion.bind(this);
      this.changeRange = this.changeRange.bind(this);
    }

    componentDidMount() {
      axios.get('./static/data/survey.json')
        .then((res) => {
          _DATA = { ...res.data };
          this.setState({
            data: { ..._DATA },
          });
        }).catch((err) => {
          console.log(err);
        });

      this.targetElement = this.domContainer.current.querySelector('.survey');
    }

    toggleVisible = (e) => {
      // e.stopPropagation();

      const { isOpen } = this.state;

      if (!isOpen) {
        // this.domContainer.current.body.classList.toggle('disable-scroll-body');
        disableBodyScroll(this.targetElement);
      } else {
        // this.domContainer.current.body.classList.remove('disable-scroll-body');
        enableBodyScroll(this.targetElement);
      }

      this.setState({
        isOpen: !this.state.isOpen,
      });
    }

    chooseAnswer = (key, id) => {
      // delete popup if survey start
      const findAncestor = (el, sel) => {
        while ((el = el.parentElement) && !((el.matches || el.matchesSelector).call(el, sel)));
        return el;
      };

      const deleteModalSurvey = () => {
        const modal = document.getElementById('survey');
        if (modal) modal.remove();
      };

      if (this.state.currentQuestion === 0) {
        if (findAncestor(this.domContainer.current, '#survey') === null) {
          deleteModalSurvey();
        }
      }
      // delete popup if survey start

      let { selectedAnswers, data } = this.state;
      const { questions } = data;
      const answer = { key, id };

      const findExistAnswer = find(selectedAnswers, item => item.key === key);

      if (findExistAnswer) {
        selectedAnswers.forEach((answer) => {
          if (answer.key === key) {
            answer.id = id;
          }
        });
      } else {
        selectedAnswers = [...selectedAnswers, answer];
      }

      selectedAnswers.forEach((answer, index) => {
        if (answer.key.indexOf('what_exactly') !== -1) {
          answer.key = `${answer.key.split('_')[0]}_${selectedAnswers[index - 1].id}`;
        }
      });

      this.setState({
        selectedAnswers,
      });

      const findedQuestion = find(questions, { key });

      if (findedQuestion.influence) {
        const newQuestions = filter(questions, (item) => {
          const { relative } = item;
          if (relative) {
            const [relKey, relId] = relative.split('_');
            if (relKey === key) {
              if (relId === id) return item;
            } else {
              return item;
            }
          } else {
            return item;
          }
        });

        data.questions = newQuestions;

        this.setState({
          data,
        });
      }

      const buttonNext = this.domContainer.current.querySelector('.survey__arrow--next');

      if (buttonNext) {
        buttonNext.click();
      }
    }

    clearAnimation = () => {
      const buttonNext = this.domContainer.current.querySelector('.survey__arrow--next');
      const question = this.domContainer.current.querySelector('.survey__question');
      if (question) {
        question.classList.add('fade');
      }
      if (buttonNext) {
        buttonNext.classList.remove('survey__arrow--disabled');
      }
      clearTimeout(this.timeoutId);
    }

    startAnimation = () => {
      const { currentQuestion, data } = this.state;
      this.timeoutId = setTimeout(() => {
        const buttonNext = this.domContainer.current.querySelector('.survey__arrow--next');
        const question = this.domContainer.current.querySelector('.survey__question');
        if (question) {
          question.classList.remove('fade');
        }
        if (buttonNext && (currentQuestion < data.questions.length - 1)) {
          buttonNext.classList.add('survey__arrow--disabled');
        }
      }, 300);
    }

    updateCurrentQuestion() {
      this.clearAnimation();

      const { currentQuestion, data } = this.state;

      if (!isEmpty(data) && currentQuestion < data.questions.length - 1) {
        this.setState({
          currentQuestion: currentQuestion + 1,
        });
      } else {
        this.setState({
          isSurveyEnded: true,
        });
      }
    }

    goToPrevQuestion = () => {
      this.clearAnimation();

      const { currentQuestion, data } = this.state;

      if (isEmpty(data)) return;

      const { questions } = data;
      const findedQuestion = questions[currentQuestion];

      if (findedQuestion.relative) {
        const originalQuestions = _DATA.questions;
        const relativeKey = findedQuestion.relative.split('_')[0];

        const findedInfluence = find(originalQuestions, item => (item.key === relativeKey && item.influence));

        const influenceIndex = originalQuestions.indexOf(findedInfluence);

        const newQuestions = filter(questions, item => (!item.relative || !item.relative.split('_')[0] === relativeKey));

        const filteredRelative = filter(originalQuestions, item => (item.relative && item.relative.split('_')[0] === relativeKey));

        filteredRelative.forEach((item, index) => {
          newQuestions.splice(influenceIndex + index + 1, 0, item);
        });

        data.questions = newQuestions;

        this.setState({
          data,
        });
      }

      const newCurrentQuestion = (currentQuestion >= 1) ? currentQuestion - 1 : 0;

      this.setState({
        currentQuestion: newCurrentQuestion,
      });
    }

    changeRange(key, id) {
      let { selectedAnswers } = this.state;
      const answer = { key, id };

      const findExistAnswer = find(selectedAnswers, item => item.key === key);

      if (findExistAnswer) {
        selectedAnswers.forEach((answer) => {
          if (answer.key === key) {
            answer.id = id;
          }
        });
      } else {
        selectedAnswers = [...selectedAnswers, answer];
      }

      this.setState({
        selectedAnswers,
      });
    }

    renderQuestions = () => {
      const { data, currentQuestion } = this.state;

      const renderError = () => (
                <div className="survey__error text-center">Произошла ошибка, попробуйте обновить страницу и повторить!</div>
      );

      if (isEmpty(data)) {
        return renderError();
      }

      const { questions } = data;

      this.startAnimation();

      if (questions.length > 0) {
        const lastQuestion = (currentQuestion === questions.length - 1);
        const nextClasses = `survey__arrow survey__arrow--next button ${!lastQuestion ? 'survey__arrow--disabled' : ''}`;
        return (
                <div>
                    <SurveyQuestion handler={questions[currentQuestion].type !== 'range' ? this.chooseAnswer : this.changeRange} data={questions[currentQuestion]} />
                    <div className="survey__navigation">
                        {currentQuestion > 0 && <button className="survey__arrow survey__arrow--prev" onClick={this.goToPrevQuestion}></button>}
                        <button className={nextClasses} onClick={this.updateCurrentQuestion}>{lastQuestion ? 'Последний шаг' : 'Далее'}</button>
                    </div>
                </div>
        );
      }
      return renderError();
    }

    getResult = () => {
      // console.log(this.state.selectedAnswers);
      const { selectedAnswers } = this.state;
      const { questions } = _DATA;
      let result = '\n';

      const rangeAnswer = find(selectedAnswers, answer => answer.key === 'responsequantity');

      if (!rangeAnswer) {
        selectedAnswers.push({
          key: 'responsequantity',
          id: 5,
        });
      }

      selectedAnswers.forEach((answer, index) => {
        const currentQuestion = find(questions, (question) => {
          if (question.relative) {
            return question.relative === answer.key;
          }
          return question.key === answer.key;
        });
        if (currentQuestion !== undefined) {
          const { answers } = currentQuestion;
          const answerTitle = currentQuestion.title;
          const answerValue = find(answers, a => a.id === answer.id);
          let text = answer.id;
          if (answerValue !== undefined) {
            text = answerValue.title.replace(/\n|\r/g, '');
          }
          if (answer.key === 'averageamount') {
            text = answers[answer.id].title.replace(/\n|\r/g, '');
          }
          result += `${answerTitle.replace(/\n|\r/g, '')}\n${text}.\n\n`;
        }
      });
      return result;
    }

    render() {
      const { isOpen, isSurveyEnded, selectedAnswers } = this.state;

      let popup;

      if (isSurveyEnded) {
        const result = this.getResult();
        sendEventToCounters('marquiz-form');
        popup = (
                <div className="survey__form_popup">
                    <div className="survey__close close" onClick={this.toggleVisible}></div>
                    <div className="survey__congratulation">
                        <div className="survey__congratulation-image"><img src="./static/congratulation.png" alt=""/></div>
                        <div className="survey__congratulation-content">
                            <p className="survey__congratulation-title">Поздравляем!</p>
                            <p className="survey__congratulation-text text-regular">По собранным данным холодные звонки вам подходят!</p>
                            <p className="survey__congratulation-text text-regular">Необходимо правильно подобрать целевую аудиторию и составить скрипт. </p>
                        </div>
                    </div>
                    <div className="survey__form">
                        <p className="survey__text text-regular">Мы подобрали подходящий продукт. Оставьте контактные данные, чтобы узнать условия.</p>
                        <p className="survey__text text-regular">После заполнения форм Вам станет доступен скрипт обработки возражений!</p>
                        <Form result={result} title="Форма после опроса" type="from_survey" />
                    </div>
                </div>
        );
      } else {
        popup = (
                <div className="survey__box">
                    <h2 className="section__subtitle section__subtitle--small"><b>Пройдите опрос</b> <span>и&#160;узнайте, подойдут ли&#160;Вам&#160;холодные звонки?</span></h2>
                    <div className="survey__popup">
                        <div className="survey__close close" onClick={this.toggleVisible}></div>
                        { this.renderQuestions() }
                    </div>
                </div>
        );
      }

      return (
             <div ref={this.domContainer} className={isOpen ? 'survey survey--open' : 'survey'}>
                 { popup }
             </div>
      );
    }
}
