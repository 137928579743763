export default function (goalName) {
  if (typeof gtag !== 'undefined') {
    gtag('event', goalName);
  }
  if (typeof ym !== 'undefined') {
    ym(75316816, 'reachGoal', goalName);
  }
  if (typeof fbq !== 'undefined') {
    fbq('track', goalName);
  }
}
