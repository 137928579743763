/* eslint-disable class-methods-use-this */
export default class AudioPlayer {
  constructor(elem) {
    this.elem = elem;
    this.className = 'audio';

    this.template = `<div class="${this.className}__frame"><svg viewBox="0 0 100 100" class="${this.className}__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" data-play="playable"><g><circle class="${this.className}__progress-track" cx="50" cy="50" r="47.45" stroke="#153F74" stroke-linecap="round" fill="none" stroke-width="5"/><circle class="${this.className}__precache-bar" cx="50" cy="50" r="47.45" stroke="#153F74" stroke-opacity="1" stroke-linecap="round" fill="none" stroke-width="5" transform="rotate(-90 50 50)"/><circle class="${this.className}__progress-bar" cx="50" cy="50" r="47.45" stroke="#2A7FE8" fill="transparent" stroke-opacity="1" stroke-linecap="round" fill="none" stroke-width="5" transform="rotate(-90 50 50)"/></g><circle class="${this.className}__controls" cx="50" cy="50" r="45" stroke="none" fill="#000000" opacity="0.0" pointer-events="all"/><g class="${this.className}__control ${this.className}__pause"><line x1="43" y1="40" x2="43" y2="60" stroke="#2A7FE8" fill="none" stroke-width="5" stroke-linecap="round"/><line x1="56" y1="40" x2="56" y2="60" stroke="#2A7FE8" fill="none" stroke-width="5" stroke-linecap="round"/></g><g class="${this.className}__control ${this.className}__play"><polygon points="45,35 65,50 45,65" fill="#2A7FE8" stroke-width="0"></polygon></g><g class="${this.className}__control ${this.className}__stop"><rect x="35" y="35" width="30" height="30" stroke="#000000" fill="none" stroke-width="1"/></g></svg>`;

    this.init();
  }

  stopAllSounds() {
    document.addEventListener('play', (e) => {
      let audios = [].slice.call(document.getElementsByTagName('audio'));
      audios.forEach((audio) => {
        if (audio !== e.target) {
          audio.pause();

          const parentAudio = audio.parentNode;

          if (parentAudio.dataset.status.indexOf('playing') !== -1) {
            parentAudio.dataset.status = 'paused';
          }
        }
      });
    }, true);
  }

  reportPosition(el, audio) {
    let progress = el.querySelector(`.${this.className}__progress-bar`);

    this.setGraphValue(progress, audio.currentTime);
  }

  setGraphValue(obj, val) {
    const audio = this.elem.querySelector('audio');
    const { pc } = this.defaults;
    const dash = pc - parseFloat(((val / audio.duration) * pc), 10);

    obj.style.strokeDashoffset = dash;

    // if (val === 0) {
    //     this.elem.dataset.status += ' done';
    // }

    if (dash === 0) {
      this.elem.dataset.status = 'playable not_started';
    }
  }

  handlerBufferLoad() {
    const { activeAudio, precache } = this.defaults;

    activeAudio.addEventListener('progress', (e) => {
      if (activeAudio.buffered.length > 0) {
        const end = activeAudio.buffered.end(activeAudio.buffered.length - 1);

        this.setGraphValue(precache, end);
      }
    });
  }

  action() {
    const { activeAudio, precache } = this.defaults;
    const { elem, elemSvg } = this;

    const { status } = elem.dataset;

    switch (status.replace('playable', '').trim()) {
      case 'not_started':
        this.stopAllSounds();
        activeAudio.play();

        activeAudio.addEventListener('timeupdate', (e) => {
          this.reportPosition(elem, activeAudio);
        });

        elem.dataset.status = 'playing';

        break;
      case 'playing':
        elem.dataset.status = 'playable paused';
        activeAudio.pause();
        break;
      case 'paused':
        elem.dataset.status = 'playable playing';
        activeAudio.play();
        break;
      case 'ended':
        elem.dataset.status = 'playable not_started';
        activeAudio.removeEventListener('timeupdate', this.reportPosition);
        break;
    }
  }

  handlerControlClick() {
    this.defaults.controls.addEventListener('click', () => {
      this.action();
    });
  }

  init() {
    this.elem.innerHTML += this.template;

    this.elem.dataset.status = 'not_started playable';

    this.elemSvg = this.elem.querySelector(`.${this.className}__icon`);
    const controls = this.elem.querySelector(`.${this.className}__controls`);
    const progress = this.elem.querySelector(`.${this.className}__progress-bar`);
    const precache = this.elem.querySelector(`.${this.className}__precache-bar`);
    const activeAudio = this.elem.querySelector('audio');

    this.defaults = {
      playObj: 'playable',
      progress,
      precache,
      activeAudio,
      controls,
      pt: this.elemSvg.createSVGPoint(),
      pc: 298.1371428256714, // 2 pi r
    };

    this.handlerControlClick();
    // this.handlerBufferLoad();
  }
}
