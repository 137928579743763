/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import 'toastify-js/src/toastify.css';
import Swiper from 'swiper';
import AudioPlayer from './audio';
import sendEventToCounters from './countersEvents';

// react component
import Survey from './survey';

// react component
import Form from './form';

if (!Number.isNaN) {
  Object.defineProperty(Number, 'isNaN', {
    value(value) {
      // eslint-disable-next-line no-self-compare
      return value !== value;
    },
  });
}

// import Swiper JS
// Swiper.use([Mousewheel]);

let requestId;

const App = {
  init() {
    this.initAudioPlayer();
    this.initCommunicationHandler();
    this.initReviewsSlider();
    this.initLoaderBody();
    this.initHeaderMenu();
    // this.initPromotion();
    this.iniCallTouch();
  },
  initPromotion() {
    const selectors = {
      promotion: '.promotion',
      close: '.promotion__close',
    };
    const classes = {
      visible: 'promotion--visible',
    };

    const promotion = document.querySelector(selectors.promotion);
    const promotionClose = document.querySelector(selectors.close);

    setTimeout(() => {
      promotion.classList.add(classes.visible);
    }, 15000);

    promotionClose.addEventListener('click', () => {
      promotion.classList.remove(classes.visible);
    });
  },
  initHeaderMenu() {
    const selectors = {
      menu: '.menu',
      link: '.menu__link',
      section: '.section',
      header: '.header',
      indicator: '.header__indicator',
      burger: '.burger',
      dropdown: '.header__dropdown',
      orderButton: '.button--order',
    };

    const menu = document.querySelector(selectors.menu);
    const menuLinks = [].slice.call(menu.querySelectorAll(selectors.link));
    const header = document.querySelector(selectors.header);
    const indicator = document.querySelector(selectors.indicator);
    const burger = document.querySelector(selectors.burger);
    const dropdown = document.querySelector(selectors.dropdown);
    const headerOffset = header.offsetHeight;
    const orderButton = [].slice.call(document.querySelectorAll(selectors.orderButton));

    const switchActiveLink = (id) => {
      menuLinks.forEach((link) => {
        if (link.getAttribute('href').substr(1) === id) {
          link.classList.add('active');
        } else {
          link.classList.remove('active');
        }
      });
    };

    const scrollToSection = (id, isCallback = true) => {
      const currentSection = document.querySelector(`${selectors.section}[data-scroll-id=${id}]`);

      if (currentSection !== null) {
        const startY = currentSection.getBoundingClientRect().top - headerOffset;
        const direction = (startY < 0) ? -1 : (startY > 0) ? 1 : 0;
        if (direction == 0) return;

        this.scrollToElement(currentSection, direction, headerOffset, isCallback ? () => {
          document.addEventListener('scroll', scrollHandler);
        } : () => {});
      }
    };

    const changeIndicatorPosition = (current) => {
      const activeMenuLink = current || document.querySelector(`${selectors.link}.active`);
      if (activeMenuLink !== null) {
        indicator.classList.remove('hidden');
        const leftLinkOffset = activeMenuLink.getBoundingClientRect().left;
        const leftMenuOffset = menu.getBoundingClientRect().left;
        const leftOffset = leftLinkOffset - leftMenuOffset;
        const linkWidth = activeMenuLink.offsetWidth;

        indicator.style.left = `${leftOffset + linkWidth / 2}px`;
      } else {
        indicator.classList.add('hidden');
      }
    };

    menuLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const id = link.getAttribute('href').substr(1);
        document.removeEventListener('scroll', scrollHandler);
        switchActiveLink(id);
        scrollToSection(id);

        hideDropdownMenu();
      });
      link.addEventListener('mouseover', (e) => {
        changeIndicatorPosition(link);
      });
      link.addEventListener('mouseout', (e) => {
        changeIndicatorPosition();
      });
    });

    const hideDropdownMenu = () => {
      burger.classList.remove('active');
      dropdown.classList.remove('expanded');
      header.classList.remove('expanded');
    };

    changeIndicatorPosition();

    const scrollHandler = () => {
      const scrollPos = window.scrollY;

      menuLinks.forEach((link) => {
        const id = link.getAttribute('href').substr(1);
        const currentSection = document.querySelector(`${selectors.section}[data-scroll-id=${id}]`);
        if (currentSection) {
          const currentSectionTop = currentSection.getBoundingClientRect().top + document.documentElement.scrollTop - headerOffset;

          if (currentSectionTop <= scrollPos && currentSectionTop + currentSection.offsetHeight > scrollPos) {
            menuLinks.forEach(link => link.classList.remove('active'));
            link.classList.add('active');
          } else {
            link.classList.remove('active');
          }
        }
      });

      changeIndicatorPosition();
    };

    document.addEventListener('scroll', scrollHandler);

    if (orderButton.length) {
      orderButton.forEach(button => button.addEventListener('click', () => {
        // document.removeEventListener('scroll', scrollHandler);
        // scrollToSection('together', false);

        const block = document.querySelector(`${selectors.section}[data-scroll-id="together"]`);

        if (block) {
          block.scrollIntoView({ behavior: 'smooth' });
          switchActiveLink(null);
          changeIndicatorPosition();
        }

        hideDropdownMenu();
        sendEventToCounters('ostavit_zayavku_v_shapke_klik');
      }));
    }

    burger.addEventListener('click', () => {
      burger.classList.toggle('active');
      dropdown.classList.toggle('expanded');
      header.classList.toggle('expanded');
    });
  },
  scrollToElement(el, direction, offset = 0, cb) {
    const duration = 3000;
    const start = new Date().getTime();

    window.cancelAnimationFrame(requestId);

    const fn = () => {
      const top = el.getBoundingClientRect().top - offset;
      const now = new Date().getTime() - start;
      let result = Math.round(top * now / duration);

      result = (result > direction * top) ? top : (result == 0) ? direction : result;

      if (direction * top <= 0) {
        if (cb) cb();
        return;
      }

      window.scrollBy(0, result);
      requestId = requestAnimationFrame(fn);
    };
    requestId = requestAnimationFrame(fn);
  },
  initLoaderBody() {
    const isMobile = document.body.clientWidth <= 544;
    setTimeout(() => {
      document.body.classList.remove('loader');
    }, isMobile ? 300 : 500);
  },
  initFormHandlers() {
    const selectors = {
      inputs: '.form__input',
      forms: '.form',
    };

    const inputs = [].slice.call(document.querySelectorAll(selectors.inputs));

    inputs.forEach((input) => {
      const field = input.closest('.form__field');

      input.addEventListener('focus', (e) => {
        field.classList.add('form__field--focus');
      });
      input.addEventListener('blur', (e) => {
        field.classList.remove('form__field--focus');
      });
      input.addEventListener('change', (e) => {
        if (input.value.length !== 0) {
          field.classList.add('form__field--filled');
        } else {
          field.classList.remove('form__field--filled');
        }
      });
    });
  },
  initReviewsSlider() {
    const reviewSlider = new Swiper('.swiper-container-reviews', {
      loop: true,
      loopedSlides: 12,
      speed: 600,
      spaceBetween: 24,
      centeredSlides: true,
      slidesPerView: 'auto',
      slideToClickedSlide: true,
      // mousewheel: true,
      freeMode: true,
      freeModeSticky: true,
    });
  },
  initCommunicationHandler() {
    const selectors = {
      cards: '.communication__card',
    };

    const cardsItems = [].slice.call(document.querySelectorAll(selectors.cards));

    cardsItems.forEach((card) => {
      card.addEventListener('mouseover', () => {
        cardsItems.forEach((item) => {
          if (item !== card) {
            item.classList.add('blured');
          }
        });
      });
      card.addEventListener('mouseout', () => {
        cardsItems.forEach(item => item.classList.remove('blured'));
      });
    });
  },
  initAudioPlayer() {
    const audio = [].slice.call(document.querySelectorAll('.audio'));
    audio.forEach(item => new AudioPlayer(item));
  },
  initStickyHeader() {
    const classes = {
      isSticky: 'is-sticky',
    };
    const selectors = {
      header: '.header__sticky',
      spacer: '.header__spacer',
    };

    const getTopDistance = () => headerElement.offsetTop;

    const headerElement = document.querySelector(selectors.header);

    if (headerElement === null) {
      return;
    }

    const spacerElement = document.querySelector(selectors.spacer);
    const stickyPoint = getTopDistance();
    let sticky = false;

    spacerElement.style.height = `${headerElement.getBoundingClientRect().height}px`;

    window.addEventListener('scroll', () => {
      const offset = window.pageYOffset;
      const distance = getTopDistance() - offset;
      if (distance <= 0 && !sticky) {
        sticky = true;
        spacerElement.style.height = `${headerElement.getBoundingClientRect().height}px`;
        headerElement.classList.add(classes.isSticky);
      } else if ((sticky && offset <= stickyPoint) && !document.body.classList.contains('no-scroll')) {
        spacerElement.style.height = 0;
        headerElement.classList.remove(classes.isSticky);
        sticky = false;
      }
    }, { passive: true });
  },
  iniCallTouch() {
    const getParameterByName = (name, url) => {
      if (!url) {
        url = window.location.href;
      }
      name = name.replace(/[\[\]]/g, '\\$&');
      const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
      const results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };

    const utm_source = (getParameterByName('utm_source') != null) ? getParameterByName('utm_source') : 'null';
    const utm_medium = (getParameterByName('utm_medium') != null) ? getParameterByName('utm_medium') : 'null';
    const utm_campaign = (getParameterByName('utm_campaign') != null) ? getParameterByName('utm_campaign') : 'null';
    const utm_content = (getParameterByName('utm_content') != null) ? getParameterByName('utm_content') : 'null';
    const utm_term = (getParameterByName('utm_source') != null) ? getParameterByName('utm_term') : 'null';

    const _ctreq = function (data) {
      const sid = '28483';
      const nid = '9';
      const request = window.ActiveXObject ? new ActiveXObject('Microsoft.XMLHTTP') : new XMLHttpRequest();
      const post_data = Object.keys(data).reduce((a, k) => { if (data[k]) { a.push(`${k}=${encodeURIComponent(data[k])}`); } return a; }, []).join('&');
      const url = `https://api-node${nid}.calltouch.ru/calls-service/RestAPI/${sid}/requests/orders/register/`;
      request.open('POST', url, true);
      request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      request.send(post_data);
    };
    if (typeof OnlinePBXWebWidget === 'undefined') { OnlinePBXWebWidget = { events: {} }; }
    OnlinePBXWebWidget.events.callrequest = function (number, op, src) {
      try {
        const phone = number;
        const sub = 'Звонок Online PBX';
        if (phone) {
          const ct_data = {
            subject: sub,
            phoneNumber: phone,
            sessionId: window.call_value,
            requestUrl: window.location.href,
            comment: `utm_source = ${utm_source} \n utm_medium = ${utm_medium} \n utm_campaign = ${utm_campaign} \n utm_content = ${utm_content} \n utm_term = ${utm_term}`,
          };
          _ctreq(ct_data);
        }
      } catch (e) { console.log(e); }
    };
  },
};

document.addEventListener('DOMContentLoaded', () => {
  App.init();
});
