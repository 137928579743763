import React, { Component } from 'react';

import Slider from 'react-rangeslider';

export default class SurveyQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rangeValue: 5,
    };
  }

    renderPick = (data, handler) => {
      const { answers, key } = data;
      return (
            <div className="survey__pick">
                {
                    answers.map((answer, i) => {
                      const { title, image, id } = answer;
                      return (
                            <div className="survey__pick_answer" onClick={() => handler(key, id || i)} key={i}>
                                <div className="survey__pick_image"><img src={image} /></div>
                                <span className="survey__pick_title text-regular">{title}</span>
                            </div>
                      );
                    })
                }
            </div>
      );
    }

    renderPickMany = (data, handler) => {
      const { answers, key } = data;
      return (
            <div className="survey__manypick">
                {
                    answers.map((answer, i) => {
                      const { title, image, id } = answer;
                      return <div className="survey__manypick_answer" onClick={() => handler(key, id || i)} key={i}>
                            <img className="survey__manypick_image" src={image} />
                            <span className="survey__manypick_title">{title}</span>
                        </div>;
                    })
                }
            </div>
      );
    }

    renderPickRadio = (data, handler) => {
      const { answers, key } = data;
      return (
            <div className="survey__radios">
                {
                    answers.map((answer, i) => {
                      const { title, id } = answer;
                      return <div className="survey__radio" onClick={() => handler(key, id || i)} key={i}>
                            <label className="survey__radio_label">
                                <input className="survey__radio_input" name={key} type="radio" value={id} />
                                <span className="survey__radio_title">{title}</span>
                            </label>
                        </div>;
                    })
                }
            </div>
      );
    }

    handleRangeChange = (value) => {
      this.setState({
        rangeValue: value,
      });
    }

    renderPickRange = (data, handler) => {
      const { range, key } = data;
      const { min, max, step } = range;

      const { rangeValue } = this.state;
      const labels = {
        0: `${min}`,
        100: `${max}`,
      };

      const changeHandler = (val) => {
        handler(key, rangeValue);
        this.handleRangeChange(val);
      };

      return (
            <div className="survey__range">
                {/* <input type="range" className="survey__range_input" name={id} step={step} min={min} max={max} /> */}
                <Slider
                    value={rangeValue}
                    min={min}
                    max={max}
                    step={step}
                    tooltip={false}
                    labels={labels}
                    onChange={changeHandler}
                />
                <div className="survey__range_value">{rangeValue}</div>
                {/* <div className='survey__range_value'>{rangeValue}</div> */}
            </div>
      );
    }

    renderTemplateByType = (data, handler) => {
      const { type } = data;
      switch (type) {
        case 'pick':
          return this.renderPick(data, handler);
        case 'pick_many':
          return this.renderPickMany(data, handler);
        case 'radio':
          return this.renderPickRadio(data, handler);
        case 'range':
          return this.renderPickRange(data, handler);
      }
    }

    render() {
      const { data, handler } = this.props;

      const { title, key } = data;

      return (
            <div className="survey__question fade" data-id={key}>
                <p className="survey__title text-center">{title}</p>
                { this.renderTemplateByType(data, handler) }
            </div>
      );
    }
}
