import React from 'react';
import ReactDOM from 'react-dom';
import Survey from './components/Survey';

// const survey = ReactDOM.render(<Survey />, document.querySelector("#survey"));

let survey;

const surveyContainers = [].slice.call(document.querySelectorAll('[data-id="survey"]'));

surveyContainers.forEach((domContainer) => {
  if (domContainer.id === 'survey') {
    survey = ReactDOM.render(<Survey />, domContainer);
  } else {
    ReactDOM.render(<Survey />, domContainer);
  }
});

const surveyButtons = [].slice.call(document.querySelectorAll('.button--survey'));
surveyButtons.forEach(button => button.addEventListener('click', (e) => {
  if (survey) survey.toggleVisible(e);
  const promotion = document.querySelector('.promotion');
  promotion.classList.remove('promotion--visible');
}));

setTimeout(() => {
  if (survey) survey.toggleVisible();
}, 30000);
