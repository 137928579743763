import React from 'react';
import ReactDOM from 'react-dom';
import Form from './components/Form';

const formContainers = [].slice.call(document.querySelectorAll('.form_container'));

formContainers.forEach((domContainer) => {
  const { type } = domContainer.dataset;
  const { title } = domContainer.dataset;
  ReactDOM.render(<Form title={title} type={type} />, domContainer);
});
