import React, { Component } from 'react';

import InputMask from 'react-input-mask';
import axios from 'axios';
import qs from 'qs';
import Toastify from 'toastify-js';
import sendEventToCounters from '../countersEvents';


export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'notSending',
      buttonText: {
        notSending: 'Отправить',
        loading: 'Отправка...',
        error: 'Ошибка',
        sending: 'Отправлено',
        robot: 'Вы робот?',
      },
      inputs: {
        name: '',
        phone: '',
        email: '',
        agreement: true,
        clients: '',
      },
      formErrors: { email: '', name: '', phone: '' },
      emailValid: false,
      nameValid: false,
      phoneValid: false,
      formValid: false,
      token: 'not_token',
    };
  }

    componentDidMount = () => {
      grecaptcha.ready(() => {
        grecaptcha.execute('6Lcf5HoaAAAAACNZbu2BuO6e2EHF33ChWJUWffWl', { action: 'submit' }).then((token) => {
          this.setState({ token });
        });
      });
    }

    handleChange = (e) => {
      const { name, value } = e.target;
      const field = e.target.closest('.form__field');

      this.setState(prevState => ({
        inputs: {
          ...prevState.inputs,
          [name]: value,
        },
      }), () => { if (value !== '+7 (___) ___-__-__') this.validateField(name, value); });

      if (value.length !== 0) {
        field.classList.add('form__field--filled');
      } else {
        field.classList.remove('form__field--filled');
      }
    }

    handleCheckbox = (e) => {
      const { name, value } = e.target;

      const { checked } = e.target;

      this.setState(prevState => ({
        inputs: {
          ...prevState.inputs,
          [name]: checked,
        },
      }), () => { this.validateField(name, value); });
    }

    handleFocus = (e) => {
      const field = e.target.closest('.form__field');
      field.classList.add('form__field--focus');
    }

    handleBlur = (e) => {
      const field = e.target.closest('.form__field');
      field.classList.remove('form__field--focus');
    }

    changeToggle = (e) => {
      const { target } = e;
      const toggle = target.closest('.toggle');
      const input = toggle.querySelector('input');
      const labels = document.querySelectorAll('.toggle__label');

      labels.forEach(label => label.classList.remove('toggle__label--active'));
      target.classList.add('toggle__label--active');

      if (target.classList.contains('toggle__label--fl')) {
        toggle.classList.remove('toggle--ul');
        toggle.classList.add('toggle--fl');

        this.setState(prevState => ({
          inputs: {
            ...prevState.inputs,
            clients: '1',
          },
        }));
      }

      if (target.classList.contains('toggle__label--ul')) {
        toggle.classList.remove('toggle--fl');
        toggle.classList.add('toggle--ul');

        this.setState(prevState => ({
          inputs: {
            ...prevState.inputs,
            clients: '-1',
          },
        }));
      }
    }

    validateField(fieldName, value, e) {
      const fieldValidationErrors = this.state.formErrors;
      let { emailValid } = this.state;
      let { nameValid } = this.state;
      let { phoneValid } = this.state;
      switch (fieldName) {
        case 'email':
          emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
          fieldValidationErrors.email = emailValid ? '' : 'Неккоректный e-mail';
          break;
        case 'name':
          nameValid = value.length >= 2;
          fieldValidationErrors.name = nameValid ? '' : 'Неккоректное имя';
          break;
        case 'phone':
          phoneValid = value.indexOf('_') === -1 && value.length >= 11;
          // console.log(value[4]);
          fieldValidationErrors.phone = phoneValid ? '' : 'Неккоректный телефон';
          break;
      }
      this.setState({
        formErrors: fieldValidationErrors,
        emailValid,
        nameValid,
        phoneValid,
      }, () => this.validateForm());
    }

    validateForm() {
      const { type } = this.props;

      // if (type === 'from_survey') {
      //     this.setState({formValid: this.state.emailValid && this.state.nameValid && this.state.phoneValid});
      // } else {
      //     this.setState({formValid: this.state.nameValid && this.state.phoneValid});
      // }

      this.setState({ formValid: this.state.nameValid && this.state.phoneValid });
    }

    changeStatus = (status) => {
      this.setState({
        status,
      });
    };

    onSubmit = (e) => {
      e.preventDefault();

      if (this.state.status !== 'notSending') return;

      grecaptcha.execute('6Lcf5HoaAAAAACNZbu2BuO6e2EHF33ChWJUWffWl', { action: 'submit' }).then((token) => {
        this.setState({ token });
      });

      this.changeStatus('loading');

      const fields = this.state.inputs;
      const result = this.props.type === 'from_survey' ? this.props.result : '';

      const recaptchaToken = this.state.token;

      const getParameterByName = (name, url) => {
        if (!url) {
          url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
      };

      const clientsFromResult = result.split('\n')[2];

      let clientsType = this.state.inputs.clients === '-1' ? 'Юр. лица' : 'Физ. лица';

      if (clientsFromResult) {
        if (clientsFromResult.indexOf('Физическим') !== -1) {
          clientsType = 'Физ. лица';
        } else {
          clientsType = 'Юр. лица';
        }
      }

      // console.log(result.split('\n'), clientsFromResult, clientsFromResult.indexOf('Физ') !== -1, clientsType);

      const utm_source = (getParameterByName('utm_source') != null) ? getParameterByName('utm_source') : 'null';
      const utm_medium = (getParameterByName('utm_medium') != null) ? getParameterByName('utm_medium') : 'null';
      const utm_campaign = (getParameterByName('utm_campaign') != null) ? getParameterByName('utm_campaign') : 'null';
      const utm_content = (getParameterByName('utm_content') != null) ? getParameterByName('utm_content') : 'null';
      const utm_term = (getParameterByName('utm_source') != null) ? getParameterByName('utm_term') : 'null';

      const data = qs.stringify({
        fields, site: 'Новый сайт', 'form-title': this.props.title, result, utm_source, utm_medium, utm_campaign, utm_content, utm_term, special: 'amnJhj91jnBBja2aolMpz', 'g-recaptcha-response': recaptchaToken, clientsType,
      });

      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data,
        timeout: 10000,
        url: 'https://calloutsale.ru/handlers/sender_outsale.php',
        // url: '/handlers/sender_outsale.php',
      };

      axios(options)
        .then((result) => {
          const data = JSON.parse(result.data);
          if (+data.status === 1) {
            this.changeStatus('sending');
            if (this.props.type === 'from_survey') {
              if (clientsType === 'Физ. лица') {
                sendEventToCounters('marquiz-finish-fl');
              } else {
                sendEventToCounters('marquiz-finish-ul');
              }
              // sendEventToCounters('marquiz-finish');
              window.open('https://disk.yandex.ru/d/AV41hYYVK016nw', '_blank');
            } else {
              if (fields.clients === '-1') {
                sendEventToCounters('form_sent_ul');
              }
              if (fields.clients === '1') {
                sendEventToCounters('form_sent_fl');
              }

              Toastify({
                text: 'Ваша заявка успешно отправлена! \n С вами свяжется менеджер в ближайшее время!',
                duration: 10000,
                newWindow: true,
                close: false,
                gravity: 'top',
                position: 'center',
                stopOnFocus: true,
                style: {
                  background: '#2A7FE8',
                  textAlign: 'center',
                  fontSize: '20px',
                },
              }).showToast();
            }
            return setTimeout(() => {
              this.resetForm();
            }, 2000);
          } if (data.status === 'robot') {
            return this.changeStatus('robot');
          }
          return this.changeStatus('error');
        })
        .catch((error) => {
          console.log(error);
          return this.changeStatus('error');
        });

      // send to calltouch
      const { name } = fields;
      const { phone } = fields;
      const calltouchID = '28483';
      const calltouchData = {
        fio: name,
        phoneNumber: phone,
        subject: 'Заявка',
        requestUrl: location.href,
        sessionId: window.call_value,
      };
      const calltouchOptions = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(calltouchData),
        timeout: 3000,
        async: false,
        url: `https://api-node9.calltouch.ru/calls-service/RestAPI/requests/${calltouchID}/register/`,
      };

      axios(calltouchOptions)
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    resetForm = () => {
      this.setState({
        status: 'notSending',
        inputs: {
          name: '',
          phone: '',
          email: '',
          agreement: true,
          clients: '',
        },
        formErrors: { email: '', name: '', phone: '' },
        emailValid: false,
        nameValid: false,
        phoneValid: false,
        formValid: false,
      });
    }

    handleOnlyWords = (e) => {
      if (!e.key.match(/^[a-zA-Zа-яА-Я ]/)) return e.preventDefault();
    }

    render() {
      const { type } = this.props;

      const { formValid, status, buttonText } = this.state;

      const emailError = this.state.formErrors.email;
      const nameError = this.state.formErrors.name;
      const phoneError = this.state.formErrors.phone;

      const formClasses = `${!formValid ? 'form form--not_valid' : 'form'}`;

      const {
        name, phone, email, agreement, clients,
      } = this.state.inputs;

      const renderEmailField = () => (
                <div className="form__field">
                    <label className="form__label">E-mail</label>
                    <input className="form__input" onFocus={this.handleFocus} onBlur={this.handleBlur} onChange={this.handleChange} value={email} type="email" name="email" />
                    { emailError !== '' && <span className="form__error">{emailError}</span> }
                </div>
      );

      const renderToggle = () => (
                <div className="form__toggle">
                    <p className="form__name">Ваши клиенты:</p>
                    <div className="toggle">
                        <div className="toggle__label toggle__label--ul" onClick={this.changeToggle}>Юр. лица</div>
                        <div className="toggle__input">
                            <input className="form__input" onChange={this.handleChange} required value={clients} type="text" name="clients" />
                            <i></i>
                        </div>
                        <div className="toggle__label toggle__label--fl" onClick={this.changeToggle}>Физ. лица</div>
                    </div>
                </div>
      );

      return (
            <form onSubmit={this.onSubmit} className={formClasses} autoComplete="off">
                <div className="form__row">
                    <div className="form__field">
                        <label className="form__label">Имя</label>
                        <input className="form__input" onKeyPress={this.handleOnlyWords} onFocus={this.handleFocus} onBlur={this.handleBlur} onChange={this.handleChange} value={name} minLength="2" maxLength="60" type="text" name="name" />
                        { nameError !== '' && <span className="form__error">{nameError}</span> }
                    </div>
                    <div className="form__field">
                        <label className="form__label">Телефон</label>
                        <InputMask className="form__input" onFocus={this.handleFocus} onBlur={this.handleBlur} onChange={this.handleChange} value={phone} type="tel" name="phone" mask="+7 (999) 999-99-99" />
                        { phoneError !== '' && <span className="form__error">{phoneError}</span> }
                    </div>
                </div>
                {type !== 'from_survey' && renderToggle()}
                <div className="form__group">
                    <input disabled={!formValid} value={buttonText[status]} type="submit" className="button form__submit" />
                    <br/><br/>
                    <div className="agreement">
                        <div className="checkbox">
                            <label className="checkbox__label">
                                <input required className="checkbox__input" type="checkbox" name="agreement" onChange={this.handleCheckbox} defaultChecked={agreement} />
                                <span className="checkbox__name">Вы даёте своё <a href="./static/privacy_policy.pdf" target="_blank">Согласие на обработку персональных данных</a></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="form__note">This site is protected by reCAPTCHA and the Google <a target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and <a target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.</div>
            </form>
      );
    }
}
